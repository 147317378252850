import React, { useState, useEffect } from "react"
import "./selection-filter.scss"

function SelectionFilter({ list, name, required }) {
  const [showFilter, setShowFilter] = useState(false)
  const [listFilter, setListFilter] = useState(list)
  const [filter, setFilter] = useState("")
  const [selected, setSelected] = useState(0)

  const handleShowFilter = (e) => {
    setShowFilter(!showFilter)
    if (showFilter) {
      setFilter("")
      setListFilter(list)
      let eleVal = document.getElementById(name).value
      let eleScrollTarget = document.getElementById(`${name}-${eleVal}-list-opt`)
      let eleScroll = document.getElementById(`${name}-list-result`)

      if (eleScrollTarget) {
        let topGoal = eleScrollTarget.offsetTop - eleScroll.offsetTop
        eleScroll.scrollTo({
          top: topGoal
        })
      }
    }
  }

  const handleFilter = (e) => {
    let val = e.target.value
    let result = list.filter(item => item.name.includes(val))
    setFilter(val)
    setListFilter(result)
  }

  const handleTriggerSelected = (e) => {
    let selectedIndex = parseInt(e.target.getAttribute("data-index"))
    let ele = document.getElementById(name)
    ele.options[selectedIndex].selected = true
    setSelected(selectedIndex)
    setShowFilter(false)
    setFilter("")
    setListFilter(list)
  }

  useEffect(() => {
    const handleCloseFilter = (e) => {
      if (document.getElementById(`${name}-selection-filter`) 
      && !document.getElementById(`${name}-selection-filter`).contains(e.target)) {
        setShowFilter(false)
        setFilter("")
        setListFilter(list)
      }
    }

    window.addEventListener("click", handleCloseFilter)

    return () => {
      window.removeEventListener("click", handleCloseFilter)
    }
  }, [])

  return (
    <div className="selection-filter" id={`${name}-selection-filter`}>
      <select name={name} id={name} required={required ? "required" : ""}>
        {list.map((opt, index) =>
          <option key={index} value={opt.val}>{opt.name}</option>
        )}
      </select>
      <div className={`selection ${showFilter ? "open" : ""}`}>
        <div className="option-selected__wrapper" onClick={handleShowFilter}>
          <div 
            className="option-selected" 
            id={`${name}-option-selected`} 
          >
            {list[selected].name}
          </div>
        </div>
        <div className={`filter-wrapper ${showFilter ? "open" : ""}`}>
          <div className="filter-wrapper__input">
            <input 
              type="text" 
              className="filter"
              value={filter} 
              onChange={handleFilter} />
          </div>
          <ul
            id={`${name}-list-result`}
            className="list-result">
            {listFilter.length > 0 ? (listFilter.map((opt, index) =>
              <li
                id={`${name}-${opt.val}-list-opt`}
                key={index}
                data-value={opt.val}
                data-index={index}
                onClick={handleTriggerSelected}
                className={selected === index ? "selected" : ""}
              >
                {opt.name}
              </li>
            )) : (
              <li className="not-found">No results found</li>
            )}
          </ul>
        </div>
      </div>
    </div>

  )
}

export default SelectionFilter