import React, {
  useEffect,
  useState
} from 'react'
import Layout from '../../components/layout'
import Banner from '../../components/Banner'
import SelectionFilter from '../../components/SelectionFilter'
import './wholesale-registration.scss'
import Background from "../../components/images/banner.jpg"
import Seo from '../../components/seo'

const countries = [
  {
    name: "country without states",
    val: "c1",
    states: null
  },
  {
    name: "country with states",
    val: "c2",
    states: [
      {
        name: "state 1",
        val: "s1"
      },
      {
        name: "state 2",
        val: "s2"
      },
      {
        name: "state 3",
        val: "s3"
      }
    ]
  },
  {
    name: "abc 1",
    val: "a1",
    states: null
  },
  {
    name: "abc 2",
    val: "a2",
    states: null
  },
  {
    name: "abc 3",
    val: "a3",
    states: null
  },
  {
    name: "abc 4",
    val: "a4",
    states: null
  },
  {
    name: "abc 5",
    val: "a5",
    states: null
  },
  {
    name: "abc 6",
    val: "a6",
    states: null
  }
]

function WholesaleRegistrationPage() {
  const [country, setCountry] = useState(countries[0])
  const [states, setStates] = useState(country.states)
  const seo = {
    title:"Wholesale Registration Page - Backa Australia",
    description:""
  }
  const banner = [{
    heading:"WHOLESALE REGISTRATION",
    image: {
      sourceUrl: Background
    },
  }]
  useEffect(() => {
    const handleChangeCountry = (e) => {
      if (e.target.classList.contains('country-list-opt')) {
        let index = e.target.getAttribute('data-index')
        setStates(countries[parseInt(index)].states)
      }
    }

    window.addEventListener('click', handleChangeCountry)

    return () => {
      window.removeEventListener('click', handleChangeCountry)
    }
  }, [])

  return (
    <Layout>
      <Seo title={seo.title} description={seo.description}/>
      <Banner
        banner={banner}
        btnNext={true}
      >
      </Banner>
      <div className="register section">
        <div className="container">
          <div className="pd-row intro">
            <p>If you are a food retailer, distributor, chef or restaurateur and would like to
              add Backa products to your offering, we would like to invite you to apply
              for a wholesale account.&nbsp;
              Backa Wholesale account holders have access to the range of products displayed on
              the website as well as our entire catalogue. Please fill in the
              fields below and we will be in touch. We look forward to working with you!</p>
          </div>
          <form className="register__form pd-row">
            <div className="input-wrapper half">
              <label htmlFor="">
                FIRST NAME&nbsp;
                <abbr title="required">*</abbr>
              </label>
              <input type="text" name="" id="" required />
            </div>
            <div className="input-wrapper half">
              <label htmlFor="">
                LAST NAME&nbsp;
                <abbr title="required">*</abbr>
              </label>
              <input type="text" name="" id="" required />
            </div>
            <div className="input-wrapper half">
              <label htmlFor="">
                BUSINESS NAME&nbsp;
                <abbr title="required">*</abbr>
              </label>
              <input type="text" name="" id="" required />
            </div>
            <div className="input-wrapper half">
              <label htmlFor="">
                ABN&nbsp;
                <abbr title="required">*</abbr>
              </label>
              <input type="text" name="" id="" required />
            </div>
            <div className="input-wrapper half">
              <label htmlFor="">
                PHONE&nbsp;
                <abbr title="required">*</abbr>
              </label>
              <input type="tel" name="" id="" required />
            </div>
            <div className="input-wrapper half">
              <label htmlFor="">
                EMAIL&nbsp;
                <abbr title="required">*</abbr>
              </label>
              <input type="email" name="" id="" required />
            </div>
            <div className="input-wrapper half">
              <label htmlFor="">WEBSITE</label>
              <input type="text" name="" id="" />
            </div>
            <div className="input-wrapper half">
              <label htmlFor="">
                COUNTRY&nbsp;
                <abbr title="required">*</abbr>
              </label>
              <SelectionFilter
                list={countries}
                name="country"
                required={true}
              />
            </div>
            <div className="input-wrapper">
              <label htmlFor="">
                ADDRESS 1&nbsp;
                <abbr title="required">*</abbr>
              </label>
              <input type="text" placeholder="Street address" name="" id="" required />
            </div>
            <div className="input-wrapper">
              <label htmlFor="">
                Address Line 2&nbsp;
                <span>(optional)</span>
              </label>
              <input type="text" placeholder="Apartment, suite, unit etc. (optional)" name="" id="" />
            </div>
            <div className="input-wrapper half full-mb">
              <label htmlFor="">
                City&nbsp;
                <abbr title="required">*</abbr>
              </label>
              <input type="text" placeholder="Town / City" name="" id="" required />
            </div>
            <div className="input-wrapper half full-mb">
              <label htmlFor="">
                State&nbsp;
                <abbr title="required">*</abbr>
              </label>
              {states ? (
                <SelectionFilter
                  list={states}
                  name="state"
                  required={true}
                />
              ) : (
                <input type="text" placeholder="State / County" name="" id="" required />
              )}
            </div>
            <div className="input-wrapper">
              <label htmlFor="">
                Postcode&nbsp;
                <abbr title="required">*</abbr>
              </label>
              <input type="text" name="" id="" placeholder="Postcode / Zip" required />
            </div>
            <div className="input-wrapper input-wrapper--submit">
              <input type="submit" value="register" />
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}

export default WholesaleRegistrationPage